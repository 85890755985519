<template>
  <div>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper :field="field">
      <div
        ref="fullScreen"
        class="code-editor-wrapper rounded"
        :class="{ fullscreen: fullScreen }"
        @keyup.esc="fullScreen = false"
      >
        <!------------ START: Code editor ------------>
        <CodeMirror
          ref="codemirror"
          v-model="$v.value.$model"
          :options="editorOptions"
          style="cursor: text"
          @input="onInput"
        />
        <!------------ END: Code editor ------------>
        <!------------ START: Overlay buttons ------------>
        <div class="overlay-buttons">
          <!------------ START: Full screen button ------------>
          <div
            class="btn btn-transparent-primary btn-icon btn-circle btn-sm btn-clean"
            @click="copyValue"
          >
            <i class="fal fa-copy" />
          </div>
          <!------------ START: Full screen button ------------>
          <!------------ START: Full screen button ------------>
          <div
            class="btn btn-transparent-primary btn-icon btn-circle btn-sm btn-clean ml-1"
            @click="fullScreen = !fullScreen"
          >
            <i class="fal fa-expand" />
          </div>
          <!------------ START: Full screen button ------------>
        </div>
        <!------------ END : Overlay buttons ------------>
      </div>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import { base } from "@/components/Tools/FormHelper/Helper/mixins";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";

import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";

/********** Languages **********/
import "codemirror/mode/php/php";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/yaml/yaml";
import "codemirror/mode/sql/sql";
// IMPORTANT: When adding language, add option to modes below and !update docs!

export default {
  components: { FieldWrapper, CodeMirror: codemirror },
  mixins: [base],
  props: {},
  data() {
    return {
      fullScreen: false,
      editorOptions: {
        tabSize: this.field.tabSize ?? 4,
        theme: "material",
        mode: "",
        lineNumbers: true,
        line: true,
        readOnly: false
      },
      modes: {
        php: "text/x-php",
        js: "text/javascript",
        json: "application/json",
        yaml: "text/x-yaml",
        sql: "text/sql"
      }
    };
  },
  computed: {},
  watch: {
    // Watch fullScreen prop
    fullScreen: function () {
      if (this.fullScreen) {
        // If fullScreen is true, request to open editor in fullscreen
        this.$refs.fullScreen.requestFullscreen();
      } else {
        // Else exit fullscreen
        document.exitFullscreen();
      }
    },
    isDisabled: function () {
      this.setReadonly();
    }
  },
  mounted() {
    this.editorOptions.mode = this.modes[this.field.lang ?? "php"];
    this.setReadonly();
  },
  methods: {
    setReadonly() {
      // Update disable state of editor
      this.$set(this.editorOptions, "readOnly", this.isDisabled);
    }
  }
};
</script>

<style lang="scss">
.CodeMirror {
  border-radius: 0.42rem;
  padding: 5px 0;
  .fullscreen & {
    height: 100vh;
  }
}
.overlay-buttons {
  display: none !important;
  position: absolute;
  top: 20px;
  right: 20px;
  .code-editor-wrapper:hover & {
    display: block !important;
  }
}
</style>
